export const url = "https://node-quizzila.mobiloitte.io";
export const socketUrl = "wss://node-quizzila.mobiloitte.io";

// export const url = "http://172.16.1.53:1990";
// export const socketUrl = "ws://172.16.1.53:1990";

// export const url = process.env.REACT_APP_BASE_URL;
// export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const currencyType = process.env.REACT_APP_CURRENCY_TYPE;

const ApiConfig = {
  login: `${url}/api/v1/user/login`,
  signup: `${url}/api/v1/user/signup`,
  verify: `${url}/api/v1/user/verifyOTP`,
  forgot: `${url}/api/v1/user/forgotPassword`,
  changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/api/v1/user/resetPassword`,
  ipaddressCheck: `${url}/api/v1/user/checkIpAddress`,
  resend: `${url}/api/v1/user/resendOtp`,
  AddBank: `${url}/api/v1/user/AddBank`,
  addBank: `${url}/api/v1/user/addBank`,

  uploadFile: `${url}/api/v1/user/uploadFile`,
  profile: `${url}/api/v1/user/getProfile`,
  editProfile: `${url}/api/v1/user/editProfile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,
  updatePassword: `${url}/api/v1/user/updatePassword`,
  myWallets: `${url}/api/v1/user/myWallets`,
  genOtp: `${url}/api/v1/user/genOtp`,
  createStripeToken: `${url}/api/v1/user/createStripeToken`,
  deposit: `${url}/api/v1/payment/buyChip`,
  depositWithCoinPayment: `${url}/api/v1/user/depositWithCoinPayment`,
  withdraw: `${url}/api/v1/user/withdraw`,
  getMyBankAccount: `${url}/api/v1/user/getMyBankAccount`,
  createPayout: `${url}/api/v1/payment/createPayout`,
  paypalWithdraw: `${url}/api/v1/payment/withdraw`,

  listCategory: `${url}/api/v1/category/listCategory`,
  spinPlay: `${url}/api/v1/user/spinPlayV1`,

  listQuizForUser: `${url}/api/v1/quiz/listQuizForUser`,
  playQuiz: `${url}/api/v1/quiz/playQuiz`,
  addQuiz: `${url}/api/v1/quiz/addQuiz`,
  editQuiz: `${url}/api/v1/quiz/editQuiz`,
  listTopFiveQuizForGuestUser: `${url}/api/v1/quiz/listTopFiveQuizForGuestUser`,
  deleteQuiz: `${url}/api/v1/quiz/deleteQuiz`,

  // GUEST USER
  spinPlayForGuestUser: `${url}/api/v1/user/spinPlayForGuestUser`,
  playQuizForGuestUser: `${url}/api/v1/quiz/playQuizForGuestUser`,
  listQuizForGuestUser: `${url}/api/v1/quiz/listQuizForGuestUser`,
  activeQuizForUser: `${url}/api/v1/quiz/activeQuizForUser`,
  playQuizForMUL: `${url}/api/v1/quiz/playQuizForMUL`,

  transactionHistory: `${url}/api/v1/user/transactionHistory`,
  coinAcceptance: `${url}/api/v1/user/coinAcceptance`,

  addAndEditQuiz: `${url}/api/v1/quiz/addAndEditQuiz`,
  listQuiz: `${url}/api/v1/quiz/listQuiz`,
  viewQuiz: `${url}/api/v1/quiz/viewQuiz`,
  viewQuizForAdmin: `${url}/api/v1/quiz/viewQuizForAdmin`,
  listQuizAnswer: `${url}/api/v1/quiz/listQuizAnswer`,

  listRoom: `${url}/api/v1/room/listRoom`,
  enableDisableLiveGame: `${url}/api/v1/room/enableDisableLiveGame`,
  editRoom: `${url}/api/v1/room/editRoom`,
  joinRoom: `${url}/api/v1/room/joinRoom`,

  viewRequestedBank: `${url}/api/v1/admin/viewRequestedBank`,
  requestWithdrawAccount: `${url}/api/v1/admin/requestWithdrawAccount`,
  userList: `${url}/api/v1/admin/userList`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  activeBlockUser: `${url}/api/v1/admin/activeBlockUser`,
  transactionListParticularUser: `${url}/api/v1/admin/transactionListParticularUser`,
  userGameActivityListParticularUser: `${url}/api/v1/admin/userGameActivityListParticularUser`,
  getChipValue: `${url}/api/v1/admin/getChipValue`,
  updateChipValue: `${url}/api/v1/admin/updateChipValue`,
  approveRejectRequest: `${url}/api/v1/admin/approveRejectRequest`,
  allTransactionList: `${url}/api/v1/admin/allTransactionList`,
};
export default ApiConfig;
