import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Box,
  Typography,
  SvgIcon,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.paper,
    "& .MuiPaper-root": {},
  },
  toolbar: {
    height: 80,
    padding: "0 20px",
    background: "#0d0d0d",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    backdropFilter: "blur(44px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      padding: "0 10px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        {/* <Hidden lgUp> */}
        <IconButton color="inherit" onClick={onMobileNavOpen}>
          <SvgIcon fontSize="40px">
            <MenuIcon style={{ color: "#fff" }} />
          </SvgIcon>
        </IconButton>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Typography variant="h6">
              {`${auth.userData.firstName} ${
                auth.userData.lastName ? auth.userData.lastName : ""
              }`}
            </Typography>
            <Typography variant="body1">{`${auth.userData.email}`}</Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
